$(document).ready(function (){

   $('#requestEditTOEFLShift').DataTable({
      processing: true,
    order: [],
 //    columnDefs: [{
 //                    targets  : [5],
 //                    orderable: false,
 //                }],
    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
    pageLength: 5,
    lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
   })

   $('#toeflPaymentUnconfirmedStatus').DataTable({
      processing: true,
    order: [],
 //    columnDefs: [{
 //                    targets  : [5],
 //                    orderable: false,
 //                }],
    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
    pageLength: 5,
    lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
   })

   $('#toeflPaymentConfirmedStatus').DataTable({
      processing: true,
    order: [],
 //    columnDefs: [{
 //                    targets  : [5],
 //                    orderable: false,
 //                }],
    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
    pageLength: 5,
    lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
   })

   $('#toeflPaymentDeletedStatus').DataTable({
      processing: true,
    order: [],
 //    columnDefs: [{
 //                    targets  : [5],
 //                    orderable: false,
 //                }],
    dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
    pageLength: 5,
    lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
   })

     $('#bom-recruitments').DataTable({
         processing: true,
         order: [],
     //    columnDefs: [{
     //                    targets  : [5],
     //                    orderable: false,
     //                }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
     })

     $('#bom-recruitments-trash').DataTable({
          processing: true,
        order: [],
     //    columnDefs: [{
     //                    targets  : [5],
     //                    orderable: false,
     //                }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
     })


     $('#talent-recruitments').DataTable({
         processing: true,
         order: [],
      //    columnDefs: [{
      //                    targets  : [5],
      //                    orderable: false,
      //                }],
         dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
         "<'row'<'col-sm-12'tr>>" +
         "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
         pageLength: 5,
         lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
      })

      $('#talent-recruitments-trash').DataTable({
         processing: true,
         order: [],
      //    columnDefs: [{
      //                    targets  : [5],
      //                    orderable: false,
      //                }],
         dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
         "<'row'<'col-sm-12'tr>>" +
         "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
         pageLength: 5,
         lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
      })



     $('#confirmed-transactions').DataTable({
          processing: true,
        order: [],
        columnDefs: [{
                        targets  : [6],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
     })

     $('#deleted-transactions').DataTable({
          processing: true,
        order: [],
        columnDefs: [{
                        targets  : [6],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
     })

     
     $('#welcomePartyUnconfirmed').DataTable({
          processing: true,
        order: [],
        columnDefs: [{
                        targets  : [6],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
     })

     $('#welcomePartyConfirmed').DataTable({
          processing: true,
        order: [],
        columnDefs: [{
                        targets  : [6],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
     })

     $('#welcomePartyTrash').DataTable({
          processing: true,
        order: [],
        columnDefs: [{
                        targets  : [6],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
     })
  

});