$(document).ready(function () {

    // Disable before submit
    $(window).on('beforeunload', function () {
        $("#btn-submit").prop('disabled', true);
        $("#loading-message").show();
        $("#submit-message").hide();
    });

    // Sidebar
    //  if($(window).width() < 1200) {
    var trigger = $('.page-content');

    trigger.click(function () {
        $("#toggled").removeClass("toggled");
    });
    //  }
    //  else {
    //      $("#toggled").addClass("toggled");
    //  }


    $("#close-sidebar").click(function () {
        $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function () {
        $(".page-wrapper").addClass("toggled");
    });

    $(".sidebar-dropdown > a").click(function () {
        $(".sidebar-submenu").slideUp(200);
        if (
            $(this)
                .parent()
                .hasClass("active")
        ) {
            $(".sidebar-dropdown").removeClass("active");
            $(this)
                .parent()
                .removeClass("active");
        } else {
            $(".sidebar-dropdown").removeClass("active");
            $(this)
                .next(".sidebar-submenu")
                .slideDown(200);
            $(this)
                .parent()
                .addClass("active");
        }
    });

    //  Show Modal
    var myModal = new bootstrap.Modal(document.getElementById('alert'));
    myModal.show();


    // TOEFL DETAIL script
    
    
    
});

