$(document).ready(function() {
    var owlClass = $(".class-carousel");
    owlClass.owlCarousel({
        autoplay: true,
        center: true,
        loop: true,
        dots: true,
        lazyLoad: true,
        items: 1,
        margin: 10,
        // stagePadding: 50,
        nav:true,
        navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        responsive: {
            0: {
                items: 1,
            },
            552: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 2,
            }
        }
    });

    var owlEvent = $(".event-carousel");
    owlEvent.owlCarousel({
        autoplay: true,
        center: true,
        loop: true,
        dots: true,
        lazyLoad: true,
        items: 1,
        margin: 10,
        // stagePadding: 50,
        nav:true,
        navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
        responsive: {
            0: {
                items: 1,
            },
            552: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 2,
            }
        }
    });

    var owlTalent = $(".talent-carousel");
    owlTalent.owlCarousel({
        autoplay: true,
        center: true,
        loop: true,
        dots: true,
        lazyLoad: true,
        items: 1,
        margin: 10,
        // stagePadding: 50,
         nav:true,
        navText:["<div class='nav-btn prev-slide-talent'></div>","<div class='nav-btn next-slide-talent'></div>"],
        responsive: {
            0: {
                items: 1,
            },
            552: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 2,
            },
            1200: {
                items: 2,
            }
        }
    });

    var owlPartnership = $(".partnership-carousel");
    owlPartnership.owlCarousel({
        autoplay: true,
        center: true,
        loop: true,
        dots: true,
        lazyLoad: true,
        items: 1,
        margin: 10,
        // stagePadding: 50,
        nav:false,
        responsive: {
            0: {
                items: 2,
            },
            552: {
                items: 3,
            },
            768: {
                items: 4,
            },
            992: {
                items: 5,
            },
            1200: {
                items: 5,
            }
        }
    });
    
});